<template>
  <div>
    <h1>{{ $t("design.title") }}</h1>
    <calendarStyling />
  </div>
</template>
<script>
export default {
  components: {
    calendarStyling() {
      return import("@/components/business/styling/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
